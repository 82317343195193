
import React, { useState, useEffect, useRef  } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { NodeService } from '../services/NodeService';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';


export default function TreeReport() {
    const [nodes, setNodes] = useState([]);

    const [dates, setDates] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [filterMode, setFilterMode] = useState('lenient');

    const [isChangeDateInterval, setIsChangeDateInterval] = useState(false)

    const [isShowNames, setIsShowNames] = useState(true);

    const [isLoading, setIsLoading] = useState(true)

    const toastError = useRef(null);



    const showError = (detail) => {
        toastError.current.show({ severity: 'error', summary: 'error', sticky: true, detail });
    };

    useEffect(() => {

        setIsLoading(true)
        setNodes([])

        NodeService.getTreeTableNodes(dates).then((data) => {

            setNodes(data)
            setIsLoading(false)

        }).catch((error) => {

            console.log(error)
            setIsLoading(false)
            showError(error.message)
        })

    }, [isChangeDateInterval]);


    const changeDatesHandler = (value) => {

        if (value && value[0] && value[1]) {

            setIsChangeDateInterval(!isChangeDateInterval)
        }
        setDates(value)
    }

    const getHeader = () => {
        return (
            <div className="card flex flex-row flex-wrap card-container gap-2">

                <div className="p-input-icon-left">

                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Искать ..." />
                </div>

                <div className="card flex justify-content-center">
                    <span className="p-float-label">
                        <Calendar inputId="dates"  value={dates} onChange={(e) => changeDatesHandler(e.value)} selectionMode="range" readOnlyInput showButtonBar  showIcon  />
                        <label htmlFor="dates">Выберите диапазон</label>
                    </span>
                </div>

               <div className="card flex justify-content-center align-items-center">
               
                        <Checkbox inputId="isShowNamesCheckbox" onChange={e => setIsShowNames(e.checked)} checked={isShowNames}></Checkbox>
                        <label className="ml-2" htmlFor="isShowNamesCheckbox">Показывать название</label>            
                </div>



                <div className="card flex justify-content-center align-self-end">

                    {isLoading &&
                         <ProgressSpinner style={{width: '35px', height: '35px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration="2.5s" />
                    }

                </div>

            </div>
        );
    };

    let header = getHeader();


    return (
        <div className="card">
        <Toast ref={toastError} />
    
        <div className="card flex flex-wrap justify-content-center gap-5">

                <TreeTable 
                        globalFilter={globalFilter} header={header} filterMode={filterMode} 
                        value={nodes} tableStyle={{ minWidth: '50rem' }}
                    >                    
                    <Column field="offer_id" header="Артикул" filter  sortable expander></Column>
                    
                    {isShowNames && <Column field="name" header="Название" tableStyle={{ fontSize: '10px' }}></Column>}
                    
                    <Column field="count" header="Количество" sortable></Column>
                    <Column field="count_all" header="Полное количество" sortable></Column>
                </TreeTable>
            </div>
        </div>
    );
}
         