import React from 'react';

import PrimeReact from 'primereact/api';
import TreeReport from './components/TreeReport'


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
// import { locale , addLocale } from 'primereact/api';

// import ru from  './locale.json'


import './App.css';

// addLocale(ru)
// locale('ru')


function App() {

  // active ripple effect
  PrimeReact.ripple = true;


  return (
    <div className="App">

      <header className="App-header">
      </header>

      <TreeReport/>

    </div>
  );
}

export default App;
