import axios from 'axios'

const report_url = 'https://api-o.aromasentir.ru/api/getStatistik'


export const NodeService = {


    async getReportFromOzon(dates) {

       
        try {

            let date_to = new Date().toISOString().split('T')[0]
            let from = new Date(); 
            from.setDate(from.getDate() - 30);
            let date_from = from.toISOString().split('T')[0]


            if (dates && dates[0] && dates[1]) {

                date_from = dates[0].toISOString().split('T')[0]
                date_to = dates[1].toISOString().split('T')[0]
                
            }

            
            const res = await axios.get(report_url, { params: { date_to, date_from }});
            const items = res.data.items

            console.log(items)

            const top_offers = {}

            const map_offers = {}

            for (let i = 0; i < items.length; i++) {


                const offer_id = items[i].dimensions[0]['offer_id']

                const getCount = (offer_id) => {

                    if (offer_id.endsWith('-1') || offer_id.endsWith('-1-new')) return 1
                    if (offer_id.endsWith('-2') || offer_id.endsWith('-2-new')) return 2
                    if (offer_id.endsWith('-3') || offer_id.endsWith('-3-new')) return 3

                    return 1
                }

                if (offer_id in top_offers) {

                   top_offers[offer_id].count += items[i].metrics[0]

                } else  {

                     top_offers[offer_id] = {

                            name: items[i].dimensions[0]['name'],
                            offer_id: items[i].dimensions[0]['offer_id'],
                            id: items[i].dimensions[0]['id'],
                            count: items[i].metrics[0],
                            quantity: getCount(items[i].dimensions[0]['offer_id'])                    
                    }
                }
            }

            const type_of_offers = [

                {key:'none',   data: {offer_id:  'Неразобрано',  count: 0, count_all: 0}, rule: '&&&', children: []},
                {key:'plasik_flalon', data: {offer_id:  'Флаконы',  count: 0, count_all: 0}, rule: '-f-', children: []},
                {key:'podveska_3d', data: {offer_id:  'Подвески 3Д',  count: 0, count_all: 0}, rule: '-p-3d', children: []},
                {key:'glass', data: {offer_id:  'Стекло',  count: 0, count_all: 0}, rule: '-gl-1', children: []},
                {key:'glass_plus_plastik', data: {offer_id:  'Стекло + пластик',  count: 0, count_all: 0}, rule: '-gl-ko', children: []},
                {key:'aromabox', data: {offer_id:  'А-боксы',  count: 0, count_all: 0}, rule: '-box', children: []},
                {key:'podveska_Premium', data: {offer_id:  'Подвески премиум',  count: 0, count_all: 0}, rule: '-p-pr', children: []},
                {key:'podveska_svetlay', data: {offer_id:  'Подвески светлые',  count: 0, count_all: 0}, rule: '-p-sv', children: []},
                {key:'podveska_leather', data: {offer_id:  'Подвески кожа',  count: 0, count_all: 0}, rule: '-p-le', children: []},
                {key:'podveska_black', data: {offer_id:  'Подвески черные',  count: 0, count_all: 0}, rule: '-p-bl', children: []},
                {key:'komplekt_fbo', data: {offer_id:  'Комплекты ФБО',  count: 0, count_all: 0}, rule: '-fbo', children: []},
                {key:'komplekt_fbs', data: {offer_id:  'Комплекты ФБС',  count: 0, count_all: 0}, rule: '-fbs', children: []},
                {key:'komplekt_ng', data: {offer_id:  'Новогодние комплекты',  count: 0, count_all: 0}, rule: '-p-ng', children: []},
                {key:'ekosha', data: {offer_id:  'Екоша',  count: 0, count_all: 0}, rule: 'a-', children: []},
                
            ]
        
        
             Object.values(top_offers).forEach(item => {

                let topItem = null
                let finded = false
                const offer_id_lower_case = item.offer_id.toLowerCase();
                let findedType = type_of_offers.find(type => offer_id_lower_case.indexOf(type.rule) !== -1)
                if (!findedType) findedType = type_of_offers[0]
                
                const match = item.offer_id.match(/\d{6}/)

                if (match) {

                    const  offer = match[0]
                    const findeTopItem = findedType.children.find(a => a.data.offer_id == offer)

                    if (!findeTopItem) {

                    topItem = {
                        key: offer,
                        data: {
                                name: item.name,
                                offer_id: offer,
                                count: 0,
                                count_all: 0,
                                },
                        children: []
                    }

                    findedType.children.push(topItem)

                            }  else {
                            topItem = findeTopItem
                            finded = true
                            }
             }
            
            const count_all = item.count * item.quantity

            const  newItem = {
                key: item.id,
                data: {
                    name: item.name,
                    offer_id: item.offer_id,
                    count: item.count,
                    count_all,
                },
                children: []
            }


            if (topItem && 'data' in topItem) {

                topItem.data.count += item.count
                topItem.data.count_all += count_all
                topItem.children.push(newItem)

            }   else {

                console.log(topItem)
            }
                findedType.data.count += item.count
                findedType.data.count_all += count_all
            })



            Object.values(top_offers).forEach(item => {

              
                const offer_id_lower_case = item.offer_id.toLowerCase();

                
                const matches = item.offer_id.match(/\d{6}/g) 
                if (!matches) return
                const aromat_artikul = matches[matches.length - 1];
                const podveska_artikul = matches[0];


                let findedType = type_of_offers.find(type => offer_id_lower_case.indexOf(type.rule) !== -1)


                if (findedType?.key === 'glass_plus_plastik' ||  
                        findedType?.key == 'komplekt_fbo' || 
                         findedType?.key == 'komplekt_fbs' || 
                         findedType?.key == 'aromabox') {

                        const root = type_of_offers.find(type => type.key ===  'plasik_flalon')
                        const finded = root.children.find(child => child.key == aromat_artikul)
                        if (finded) {

                            const  newItem = {
                                key: offer_id_lower_case,
                                data: {
                                    name: item.name,
                                    offer_id: offer_id_lower_case,
                                    count: item.count,
                                    count_all: item.count,
                                },
                                children: []
                            }

                            finded.children.push(newItem)
                            finded.data.count += newItem.data.count
                            root.data.count  += newItem.data.count
                            
                        }
                        
                        
                }


                if ( findedType?.key == 'komplekt_fbo' || findedType?.key == 'komplekt_fbs' ) {

                    const root = type_of_offers.find(type => type.key ===  'podveska_3d')
                    const finded = root.children.find(child => child.key == podveska_artikul)

                    if (finded) {

                    const  newItem = {
                        key: offer_id_lower_case,
                        data: {
                            name: item.name,
                            offer_id: offer_id_lower_case,
                            count: item.count,
                            count_all: item.count,
                        },
                        children: []
                    }

                    finded.children.push(newItem)
                    finded.data.count += newItem.data.count
                    root.data.count  += newItem.data.count
                    
                }
                
                
        }
                    
                

                

                
            })

           
            return type_of_offers

        } catch (error) {
            
            return Promise.reject(error)
        }


    },


    getTreeTableNodes(dates) {
        return Promise.resolve(this.getReportFromOzon(dates));
    },

};
